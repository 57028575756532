import React, { Suspense, lazy, useEffect, useState, startTransition } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useStore from "./Stores/useStore";
import ModalAlert from "./components/ModalAlert/ModalAlert";
import io from "socket.io-client";
import "./App.css";

// Ленивая загрузка компонентов
const BottomNav = lazy(() => import("./components/BottomNav/BottomNav"));
const Player = lazy(() => import("./view/Music/components/Player"));
const Frieds = lazy(() => import("./view/Frieds/Frieds"));
const Feed = lazy(() => import("./view/Feed/Feed"));
const Edit = lazy(() => import("./view/Edit/Edit"));
const StatusPost = lazy(() => import("./view/StatusPost/StatusPost"));
const Music = lazy(() => import("./view/Music/Music"));
const Story = lazy(() => import("./view/Story/Story"));
const Menu = lazy(() => import("./components/Menu/Menu"));
const Messages = lazy(() => import("./view/Messages/Messages"));
const Chats = lazy(() => import("./view/Chats/Chats"));
const Main = lazy(() => import("./view/Main/Main"));
const Notification = lazy(() => import("./view/Notifications/Notifications"));
const Login = lazy(() => import("./view/Login/Login"));
const Register = lazy(() => import("./view/Register/Register"));
const Verification = lazy(() => import("./view/Verification/Verification"));
const Accounts = lazy(() => import("./view/Accounts/Accounts"));
const Profile = lazy(() => import("./view/Profile/Profile"));

const App = () => {
  const location = useLocation();
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const { modalOpen, tracks, setUser,bottomNavVisible,setConnected } = useStore();
  const isFirstLoad = React.useRef(true);
  const socketRef = React.useRef(null);
  const [useSocket, setUseSocket] = React.useState(null);
  const token = localStorage.getItem('token');
  const [error,setError] = useState("")
  const navigate = useNavigate();

  // Инициализация подключения к WebSocket
  useEffect(() => {
    if (!socketRef.current) {
      const socket = io.connect('http://192.168.0.108:8080/', {
        transports: ['websocket', 'polling'],
        withCredentials: true,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 500,
        reconnectionDelayMax: 5000,
        timeout: 10000,
        secure: true,
        query: { token }  // Передача токена в query-параметре
      });

      socket.on('connect', () => {
        setConnected(true)
      });

      socket.on('disconnect', (reason) => {
        setConnected(false)
      });

      socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });

      socket.on("auth_success", (response) => {
        setUser(response);
      });

      setUseSocket(socket);
      socketRef.current = socket;
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [token]);

  // Аутентификация при подключении
  useEffect(() => {
    if (useSocket && token) {
      // Обработка ответа на успешную аутентификацию

      // Обработка ошибок аутентификации
      useSocket.on("auth_error", (error) => {
        if (error.message === "Токен недействителен или просрочен.") {
          localStorage.removeItem("token");
          setError("Сессия истекла")
        } else if (error.message === "Невалидный токен") {
          localStorage.removeItem("token");
          setError("Ошибка сессий")
        }
        // Перенаправление на страницу логина, если токен невалиден
        navigate("/");
      });

      // Очистка событий после размонтирования компонента
      return () => {
        useSocket.off("auth_success");
        useSocket.off("auth_error");
      };
    }
  }, [useSocket, token, navigate]);

  useEffect(()=>{
    if(!token){
      setError("Некоторые функции находятся на стадии бета-тестирования. \n Спасибо за понимание!")
    }
  },[])

  // Логика перенаправления на основе токена
  useEffect(() => {
    if (token) {
      // Перенаправляем на страницу /feed, если текущий путь - /login, /register или /
      if (location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/reset" || location.pathname === "/") {
        navigate('/feed');
      }
    } else {
      // Если токена нет и путь не один из перечисленных, перенаправляем на главную страницу
      if (
        location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/reset" &&
        location.pathname !== "/verify"
      ) {
        navigate('/');
      }
    }
  }, [token, location.pathname, navigate]);

  return (
    <div className="App">
      {location.pathname === "/" || location.pathname === "/story" || location.pathname === "/accounts" || location.pathname === "/login" || location.pathname === "/messages" || location.pathname === "/register" ? null : (
        (bottomNavVisible && 
          <Suspense fallback={undefined}><BottomNav socket={useSocket} setError={setError} /></Suspense>
        )
      )}
      {location.pathname === "/" || location.pathname === "/story" || location.pathname === "/accounts" || location.pathname === "/login" || location.pathname === "/messages" || location.pathname === "/register" ? null : (
        <Suspense fallback={undefined}>
          {modalOpen && <Menu />}
        </Suspense>
      )}

      <Suspense fallback={undefined}>
      {tracks.length > 0 && (
            <Player
              hidePlayer={location.pathname.startsWith('/story') || location.pathname.startsWith('/messages')}
            />
          )}
      </Suspense>

      {error && <ModalAlert message={error} onClose={() => setError(false)} />}


      <AnimatePresence mode="wait" style={{ willChange: 'auto' }}>
        <Suspense fallback={undefined}>
          <motion.div
            key={location.pathname}
            style={{ willChange: 'auto' }}
            initial={{ opacity: 0, x: isFirstLoad.current ? 0 : 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.25 }}
          >
            <Routes location={location}>
              <Route path="/" element={<Main />} />
              <Route path="/login" element={<Login socket={useSocket} />} />
              <Route path="/register" element={<Register socket={useSocket} />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/friends/:username" element={<Frieds socket={useSocket} />} />
              <Route path="/notifications" element={<Notification socket={useSocket} />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/profile/:username" element={<Profile socket={useSocket} setError={setError} />} />
              <Route path="/edit" element={<Edit socket={useSocket} />} />
              <Route path="/feed" element={<Feed socket={useSocket} />} />
              <Route path="/post/:id" element={<StatusPost socket={useSocket} />} />
              <Route path="/music" element={<Music />} />
              <Route path="/story" element={<Story />} />
              <Route path="/chats" element={<Chats socket={socketRef.current} />} />
              <Route path="/messages/:id" element={<Messages socket={socketRef.current} />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </motion.div>
        </Suspense>
      </AnimatePresence>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
