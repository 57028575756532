import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: #0e0e0e; /* Темно-серый фон */
  border-radius: 12px;
  padding: 20px 20px;
  width: calc(100% - 150px);
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Тень */
`;

const Message = styled.p`
  color: #ffffff;
  font-size: 15px;
  margin: 0 0 12px;
  font-weight:700;
  line-height: 1.4;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #007aff; /* Синий цвет текста */
  font-size: 15.5px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
  outline: none;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

const Action = styled.div`
  width: 100%;
  height: 35px;
`

const ModalAlert = ({message,onClose }) => {
  return (
    <Overlay>
      <Modal>
        <Message>
            {message && message}
        </Message>
        <Action>
          <Button onClick={onClose}>OK</Button>
        </Action>
      </Modal>
    </Overlay>
  );
};

export default React.memo(ModalAlert);
