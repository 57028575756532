import { create } from 'zustand';

const useStore = create((set, get) => ({
  modalOpen: false,
  openModal: () => set((state) => (!state.modalOpen ? { modalOpen: true } : {})),
  closeModal: () => set((state) => (state.modalOpen ? { modalOpen: false } : {})),

  playerOpen: false,
  isPlaying: false,
  currentTrackIndex: 0,
  volume: 1.0, // Значение громкости по умолчанию (от 0.0 до 1.0)
  tracks: [],
  user: null,  // Здесь мы будем хранить объект пользователя, а не массив

  openPlayer: () => set((state) => (!state.playerOpen ? { playerOpen: true } : {})),
  closePlayer: () => set((state) => (state.playerOpen ? { playerOpen: false } : {})),
  play: () => set((state) => (!state.isPlaying ? { isPlaying: true } : {})),
  pause: () => set((state) => (state.isPlaying ? { isPlaying: false } : {})),

  nextTrack: () => set((state) => {
    const { tracks, currentTrackIndex } = state;
    const hasTracks = tracks.length > 0;
    return hasTracks
      ? {
          currentTrackIndex: (currentTrackIndex + 1) % tracks.length,
          isPlaying: true,
        }
      : {};
  }),

  previousTrack: () => set((state) => {
    const { tracks, currentTrackIndex } = state;
    const hasTracks = tracks.length > 0;
    return hasTracks
      ? {
          currentTrackIndex: (currentTrackIndex - 1 + tracks.length) % tracks.length,
          isPlaying: true,
        }
      : {};
  }),

  restartTrack: () => set((state) => ({
    isPlaying: true, // включаем воспроизведение
    currentTrackIndex: state.currentTrackIndex,
  })),

  setTracks: (tracks) => set((state) => (state.tracks !== tracks ? { tracks } : {})),

  // Функция для установки пользователя
  setUser: (user) => set((state) => {
    // Простая функция глубокого сравнения
    const deepEqual = (obj1, obj2) => {
      if (obj1 === obj2) return true; // Сравнение по ссылке
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
      }
  
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      if (keys1.length !== keys2.length) return false;
  
      return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
    };
  
    // Если пользователь не изменился, не обновляем store
    if (!deepEqual(state.user, user)) {
      return { user };
    }
    return {};
  }),
  
  setCurrentTrack: (index) => set((state) => {
    if (state.currentTrackIndex !== index) {
      return {
        currentTrackIndex: index,
        isPlaying: true,
        playerOpen: true,
      };
    }
    return {};
  }),

  setVolume: (volume) => set((state) => {
    const clampedVolume = Math.min(Math.max(volume, 0.0), 1.0);
    return state.volume !== clampedVolume ? { volume: clampedVolume } : {};
  }),

  clearTracks: () => set(() => ({
    tracks: [],
    currentTrackIndex: 0,
    isPlaying: false,
  })),

  // Состояние для видимости BottomNav
  bottomNavVisible: true,
  showBottomNav: () => set({ bottomNavVisible: true }),
  hideBottomNav: () => set({ bottomNavVisible: false }),
  
  connected: false,
  setConnected: (isConnected) => set({ connected: isConnected }),

}));

export default useStore;
